@import url(https://fonts.googleapis.com/css?family=Roboto:200,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab);
.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (max-width: 768px) {
    html {
        font-size: 16px;
    }
}

.navbar {
    background-color: white;
    font-family: 'Roboto', 'Open-Sans', sans-serif;
    overflow-x: hidden;
}

.nav-brand {
    color: #f85b01 !important;
    font-size: 16px;
    text-transform: uppercase;
    height: auto;
    font-weight: 700;
    letter-spacing: 4px;
}

    .nav-brand:hover {
        color: #000080 !important;
        cursor: pointer;
    }

.navbar-brandname {
}

.navbar-nav {
    text-transform: uppercase;
    height: auto;
}

    .navbar-nav > li > a {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 11px;
    }

    .navbar-nav .nav-item {
        color: #000080;
        letter-spacing: 0px;
    }

        .navbar-nav .nav-item > .active {
            border: 1px solid #f85b01;
            color: #000080 !important;
            background-color: white;
        }

        .navbar-nav .nav-item:hover {
            border: 1px solid rgba(0, 0, 0, 0.8);
            color: #000080;
            cursor: pointer;
        }


@media (max-width: 500px) {
    .short-text {
        display: inline-block;
    }

    .full-text {
        display: none;
    }
}

@media (min-width: 501px) {
    .short-text {
        display: none;
    }

    .full-text {
        display: inline-block;
    }
}

/*=================================================
||				1. Imports 
==================================================*/

/*=================================================
||				2. Global
==================================================*/

body {
    font-family: 'Roboto', 'Open-Sans', sans-serif;
    overflow-x: hidden;
    color: #313131;
    padding-top: 45px;
}

@media (max-width: 500px) {
    .small-screen {
        display: inline;
    }

    .big-screen {
        display: none;
    }
}

@media (min-width: 501px) {
    .small-screen {
        display: none;
    }

    .big-screen {
        display: inline;
    }
}

a {
    color: #000080;
}

.white-text {
    color: #fff;
}

.white-text-hover:hover {
    color: white;
}

.black-text {
    color: black;
}

.orange-text {
    color: #f85b01;
}

.blue-text {
    color: #000080;
}

.white-background {
    background: white;
}

.blue-background {
    background: #000080;
}

.orange-background {
    background: #f85b01;
}

.section-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: auto;
}

.less-bottom-pad {
    padding-bottom: 10px;
}

.section-title {
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    padding-bottom: 36px;
}

.subsection-title {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    padding-bottom: 16px;
}

.underline-blue {
    border-bottom: solid 3px #000080;
    width: auto;
    padding-bottom: 9px;
}

.underline-white {
    border-bottom: solid 3px white;
    width: auto;
    padding-bottom: 9px;
}

.underline-orange {
    border-bottom: solid 3px #f85b01;
    width: auto;
    padding-bottom: 9px;
}

/*=================================================
||				3. Header
==================================================*/
.intro {
    background: url(/static/media/header.f2bde659.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    padding-top: 75px;
}


.intro-wrapper {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    position: relative;
}

.intro-table {
    display: table;
    height: 70vh;
    width: 100%;
}

@media (max-width: 500px) {
    .intro-title {
        font-size: 40px;
        letter-spacing: 4px;
        font-weight: 900;
    }
    .intro-subtitle {
        font-size: 20px;
        letter-spacing: 4px;
        font-weight: 900;
    }
}

@media (min-width: 501px) {
    .intro-title {
        font-size: 50px;
        letter-spacing: 4px;
        font-weight: 900;
    }

    .intro-subtitle {
        font-size: 24px;
        letter-spacing: 4px;
        font-weight: 900;
    }
}

/*=================================================
||				4. Modal
==================================================*/

.modal-header {
    background: #000080;
}

.modal-title {
    color: #f85b01;
}

.modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width: inherit;
    height: inherit;
    /* To center horizontally */
    margin: 0 auto;
    pointer-events: all;
    /*border: 3px solid #ff0000;*/
}

.close {
    opacity: 0.75;
    color: white;
}

.table-success, .table-success > th {
    background-color: #000080;
    color: white;
    font-weight: 600;
    border: 1px solid #dee2e6;
}

.table-info, .table-info > td {
    background-color: white;
    color: #f85b01;
    font-weight: 600;
    border: 1px solid #dee2e6;
}

.cattle-image {
    width: auto;
    height: auto;
    max-height: 100%;
    margin: auto;
    max-width: 100%;
}

.cattle-video {
    width: auto;
    height: auto;
    max-height: 100%;
    margin: auto;
    max-width: 100%;
}

/*=================================================
||				5. About J&M Ranch
==================================================*/
.about-jmranch {
    background: #ffffff;
    position: relative;
}
    .about-jmranch .underline {
        margin-bottom: 50px;
    }

.about-jmranch-img {
    float: right;
    width: 100%;
    max-width: 300px;
    margin-top: 0px;
    display: block;
    margin-bottom: 50px;
    margin-left: 20px;
    clear: both;
    height: auto;
}

.about-jmranch-text {
    display: block;
    font-size: 16px;
    font-weight: 300;
}

/*=================================================
||				6. Herd Sires
==================================================*/
.featured-cattle-img {
    position: relative;
    overflow: hidden;
    text-align: center;
}

    .featured-cattle-img .featured-cattle-item {
        width: 100%;
        height: auto;
        transition: all .6s ease-in-out;
    }

    .featured-cattle-img:hover .featured-cattle-img-hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .6s ease-in-out;
        background: rgba(0, 0, 0, 0.5);
    }

.plus {
    display: none;
}

.featured-cattle-img:hover .plus {
    display: inline-block;
    top: 40%;
    left: 45%;
    position: absolute;
}

.featured-cattle-img:hover .featured-cattle-item {
    transform: scale(1.1);
}

.featured-cattle-item-details {
    margin: 20px 0px 50px;
}

.featured-cattle-item-name {
    display: inline-block;
    font-size: 15px;
}

    .featured-cattle-heart, .featured-cattle-item-name small {
        font-size: 13px;
        color: #969595;
    }

.featured-cattle-heart {
    float: right;
    display: inline-block;
    margin-top: 10px;
}

/*=================================================
||				7. Bulls for Sale
==================================================*/

.bulls-for-sale {
    color: white;
}

.iframe-bull-list {
    width: 100%;
    height: 550px;
}

/*=================================================
||				8. Links
==================================================*/

.img-logo {
    height: 120px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

/*=================================================
||				9. Contact Info
==================================================*/

.contact-group {
    display: flex;
    margin: auto;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
}

.contact-text {
    font-size: 16px;
    font-weight: 300;
    display: block;
    text-align: center;
    padding: 0 50px 30px;
    min-width: 200px;
}

.fb-page {
    display: block !important;
    text-align: center;
}

/*=================================================
||				10. Footer
==================================================*/
footer {
    background: #eaeaea;
    padding: 20px 0px;
}

.copy {
    font-size: 14px;
    font-weight: 300;
    color: #929292;
    padding: 10px 0px;
    display: inline-block;
}

.icon {
    height: 40px;
    width: 40px;
    text-align: center;
    padding: 8px;
    display: inline-block;
    border: 1px solid transparent;
}

.news-articles {
    text-align:center;
}
