.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (max-width: 768px) {
    html {
        font-size: 16px;
    }
}

.navbar {
    background-color: white;
    font-family: 'Roboto', 'Open-Sans', sans-serif;
    overflow-x: hidden;
}

.nav-brand {
    color: #f85b01 !important;
    font-size: 16px;
    text-transform: uppercase;
    height: auto;
    font-weight: 700;
    letter-spacing: 4px;
}

    .nav-brand:hover {
        color: #000080 !important;
        cursor: pointer;
    }

.navbar-brandname {
}

.navbar-nav {
    text-transform: uppercase;
    height: auto;
}

    .navbar-nav > li > a {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 11px;
    }

    .navbar-nav .nav-item {
        color: #000080;
        letter-spacing: 0px;
    }

        .navbar-nav .nav-item > .active {
            border: 1px solid #f85b01;
            color: #000080 !important;
            background-color: white;
        }

        .navbar-nav .nav-item:hover {
            border: 1px solid rgba(0, 0, 0, 0.8);
            color: #000080;
            cursor: pointer;
        }


@media (max-width: 500px) {
    .short-text {
        display: inline-block;
    }

    .full-text {
        display: none;
    }
}

@media (min-width: 501px) {
    .short-text {
        display: none;
    }

    .full-text {
        display: inline-block;
    }
}
